import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "./App.css";
import "./scss/style.scss";
import { thunks } from "./store";


// Toaster
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const loading = (
  <div className="pt-3 text-center bg-slate-50">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const LoginPage = React.lazy(() => import("./views/pages/auth/login/LoginPage"));
const SurveyDescription1 = React.lazy(() => import("./views/pages/survey/first_intro_page.js"))
const SurveyDescription2 = React.lazy(() => import("./views/pages/survey/second_intro_page"))
const SurveyDescription3 = React.lazy(() => import("./views/pages/survey/third_intro_page"))
const SurveyPage = React.lazy(() => import("./views/pages/survey/surveypage"))
const ServeyUser = React.lazy(() => import("./views/pages/survey/userdetails"))
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));
const ErrorPage = React.lazy(() => import("./views/pages/errorPage"));
const ThankYouPage = React.lazy(() => import("./views/pages/survey/thank_you"));


function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    // Check access token
    dispatch(thunks.user.checkToken());
  }, []);

  return (
    <BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <React.Suspense fallback={loading}>
        <Switch>
          <Route
            exact
            path="/login"
            name="Login Page"
            render={(props) => <LoginPage {...props} />}
          />
          <Route
            exact
            path="/survey/:id"
            name="Survey Page"
            render={(props) => <SurveyDescription1 {...props} />}
          />
          <Route
            exact
            path="/surveydescription2"
            name="Survey Page"
            render={(props) => <SurveyDescription2 {...props} />}
          />
          <Route
            exact
            path="/surveydescription3"
            name="Survey Page"
            render={(props) => <SurveyDescription3 {...props} />}
          />
          <Route
            exact
            path="/userdetails"
            name="Survey User"
            render={(props) => <ServeyUser {...props} />}
          />
          <Route
            exact
            path="/thankyou"
            name="Thank You"
            render={(props) => <ThankYouPage {...props} />}
          />
          <Route
            exact
            path="/surveypage"
            name="Survey Page"
            render={(props) => <SurveyPage {...props} />}
          />
          <Route
              path="/admin"
              name="Admin Page"
              render={(props) => <DefaultLayout {...props} />}
          />
          <Route
              path="/designer"
              name="Designer Page"
              render={(props) => <DefaultLayout {...props} />}
          />
           <Route
              path="/user"
              name="User Profile"
              render={(props) => <DefaultLayout {...props} />}
          />
          <Route
              path="/404page"
              name="Error Page"
              render={(props) => <ErrorPage {...props} />}
          />
          <Redirect
            to={{
              pathname: "/login",
            }}
          />
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  );
}

export default App;
